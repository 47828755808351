import { create } from 'zustand';

import { ShadowImages } from './Shadow.types';

const shadowStore = create<{
  shadows: ShadowImages | null;
  setShadows: (shadows: ShadowImages) => void;
}>((set) => ({
  shadows: null,
  setShadows: (shadows: ShadowImages) => set({ shadows }),
}));

export default shadowStore;
