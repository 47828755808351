import { gsap } from 'gsap';
import { RefObject } from 'react';

import { EaseType } from '~/utils/singletons/Easing';

export const fadeInTimeline = ({
  $modal,
  $modalContent,
  $modalBackground,
  $modalFooter,
  onStart,
  onComplete,
}: {
  $modal: RefObject<HTMLElement>;
  $modalContent: RefObject<HTMLElement>;
  $modalBackground?: RefObject<HTMLElement>;
  $modalFooter?: RefObject<HTMLElement>;
  onStart?: () => void;
  onComplete?: () => void;
}) => {
  const tl = gsap.timeline({ onStart, onComplete });
  tl.set($modal.current, {
    opacity: 1,
  });

  if ($modalBackground?.current) {
    tl.fromTo(
      $modalBackground.current,
      {
        '--blur-progress': 0,
        opacity: 0,
      },
      {
        '--blur-progress': 1,
        opacity: 1,
        duration: 0.4,
        ease: EaseType.DEFAULT,
      },
    );
  }

  tl.fromTo(
    $modalContent.current,
    {
      opacity: 0,
      scale: 1.03,
    },
    {
      opacity: 1,
      scale: 1,
      duration: 0.7,
      ease: EaseType.DEFAULT,
    },
    '<+0.3',
  );

  if ($modalFooter?.current) {
    tl.fromTo(
      $modalFooter.current,
      {
        x: '70%',
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        duration: 0.7,
        ease: EaseType.BASIC_BUTTER,
      },
      '<+0.4',
    );
  }
  return tl;
};

export const fadeOutTimeline = ({
  $modalContent,
  $modal,
  $modalBackground,
  $modalFooter,
  onStart,
  onComplete,
}: {
  $modal: RefObject<HTMLElement>;
  $modalContent: RefObject<HTMLElement>;
  $modalBackground?: RefObject<HTMLElement>;
  $modalFooter?: RefObject<HTMLElement>;
  onStart?: () => void;
  onComplete?: () => void;
}) => {
  const tl = gsap.timeline({ onStart, onComplete });
  tl.to($modalContent.current, {
    opacity: 0,
    scale: '+=0.03',
    duration: 0.7,
    overwrite: true,
    ease: EaseType.DEFAULT,
  });

  if ($modalBackground?.current) {
    tl.fromTo(
      $modalBackground.current,
      {
        '--blur-progress': 1,
        opacity: 1,
      },
      {
        '--blur-progress': 0,
        opacity: 0,
        duration: 0.7,
        ease: EaseType.DEFAULT,
      },
      '<+0.2',
    );
  }

  if ($modalFooter?.current) {
    tl.fromTo(
      $modalFooter.current,
      {
        opacity: 1,
      },
      {
        opacity: 0,
        duration: 0.7,
        ease: EaseType.DEFAULT,
      },
      '<',
    );
  }

  tl.to(
    $modal.current,
    {
      opacity: 0,
      duration: 0.7,
      ease: EaseType.DEFAULT,
    },
    '<',
  );

  return tl;
};
