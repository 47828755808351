import { KeyboardEvent, ReactNode, RefObject } from 'react';

export enum ModalBackgroundType {
  BLUR = 'blur',
  ALPHA = 'alpha',
}

// Kinda inspired by Vue's transition JS Hook
type TransitionParameters = {
  $modal: RefObject<HTMLElement>;
  $modalContent: RefObject<HTMLElement>;
  $modalBackground: RefObject<HTMLElement>;
  done: () => void;
};

export type TransitionFunction = (params: TransitionParameters) => void;

export type ModalProps = {
  children?: ReactNode;
  /**
   * Background type, alpha or blur
   */
  backgroundType?: ModalBackgroundType;
  backgroundClassName?: string;
  contentClassName?: string;
  wrapperClassName?: string;
  closeButtonClassName?: string;
  className?: string;
  /**
   * Boolean to use the default close button
   */
  defaultCloseButton?: boolean;
  /**
   * Footer node, ex. for video playlist
   */
  footer?: ReactNode;
  /**
   * Boolean to trigger closing animation
   */
  shouldClose?: boolean;
  /**
   * Boolean for whether the modal has a shadow
   */
  hasShadow?: boolean;
  /**
   * Custom transition enter function passed from component
   */
  transitionEnter?: TransitionFunction;
  /**
   * Custom transition leave function passed from component
   */
  transitionLeave?: TransitionFunction;
  /**
   * Callack for when the modal starts entering (default transition only)
   */
  onEnter?: () => void;
  /**
   * Callack for when the modal starts leaving (default transition only)
   */
  onLeave?: () => void;
  onKeyDown?: (e: globalThis.KeyboardEvent) => void;
  /**
   * Function that should change the state that triggers unmounting the modal  */
  onClose?: () => void;
  /**
   * Boolean for whether the modal should be shown, set to true by default for modals that only mount when triggered by user interaction. Set to false by default for modals always present in the dom.  */
  showModal?: boolean;
};
