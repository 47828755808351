'use client';
/**
 * Base wrapper for UI/action buttons, such as the play button and close button
 * @param size Size of the button, defaults to "default"
 * @param tag HTML tag to render for the button, defaults to <button>
 * @param buttonColorScheme Color scheme for the button, defaults to glass
 * @param label A visually hidden label, used for aria-label
 * @param disabled If the button is disabled
 * @param onClick Callback function for when the button is clicked
 * @param iconClassName Classname applied to the SVG icon in the button
 * @param className
 * @param children
 * @example <UiButtonBase> {children} </UiButtonBase>
 */

import { ForwardedRef, forwardRef, useImperativeHandle, useRef } from 'react';

import { cn } from '~/utils';

import { ForwardedButtonRef } from '../Ctas/Button/Button.types';
import styles from './UIButtonBase.module.css';
import { UIButtonProps } from './UIButtonBase.types';

const UIButtonBase = (
  {
    size = 'default',
    tag = 'button',
    buttonColorScheme = 'glass',
    label,
    disabled,
    onClick,
    iconClassName,
    className,
    children,
  }: UIButtonProps,
  ref: ForwardedRef<ForwardedButtonRef>,
) => {
  const renderClass = cn(
    styles.uiButton,
    size === 'small' && styles.sizeSmall,
    className,
    styles[buttonColorScheme],
  );

  const RenderTag = tag;

  const $button = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      $button,
    }),
    [],
  );

  return (
    <RenderTag
      className={renderClass}
      onClick={onClick}
      ref={$button}
      disabled={disabled}
    >
      <figure className={cn(styles.buttonIcon, iconClassName)}>
        {children}
      </figure>
      <span className="visuallyHidden">{label}</span>
    </RenderTag>
  );
};

export default forwardRef<ForwardedButtonRef, UIButtonProps>(UIButtonBase);
