// https://github.com/Frameio/vapor/blob/main/packages/vapor/src/hooks/useFocusTrap/useFocusTrap.ts
import { RefObject, useCallback } from 'react';
import { FocusableElement, tabbable } from 'tabbable';

/**
 * Override the default browser tabbing behavior to ensure that
 * tabbing remains localized to a specific element. This is particularly
 * useful for supporting accessibility in components like
 * modals.
 */

export default function useFocusTrap(parentRef: RefObject<HTMLElement>) {
  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLElement> | globalThis.KeyboardEvent) => {
      if (e.key === 'Tab') {
        e.preventDefault();
        e.stopPropagation();

        // determine the sequence of tabbable elements contained
        // within our parent
        const sequence = tabbable(parentRef.current as Element);

        if (sequence.length === 0) {
          return;
        }

        let currentIndex = 0;

        const currentlyFocused = sequence.some(
          (element: FocusableElement, index: number) => {
            if (element === document.activeElement) {
              currentIndex = index;
              return true;
            }
            return false;
          },
        );

        if (!currentlyFocused) {
          sequence[currentIndex].focus();
          return;
        }

        const backwards = e.shiftKey;
        const nextIndex = backwards ? currentIndex - 1 : currentIndex + 1;
        const nextToFocus = sequence[nextIndex];

        if (nextToFocus) {
          nextToFocus.focus();
          return;
        }

        if (backwards) {
          sequence[sequence.length - 1].focus();
        } else {
          sequence[0].focus();
        }
      }
    },
    [parentRef],
  );

  return onKeyDown;
}
