'use client';
/**
 * The default CTA button, used to render buttons from Portable Text. Composes functionality from ButtonBase
 * @param props see ButtonBase props
 * @example <Button props={props} />
 */

import { ForwardedRef, forwardRef, useImperativeHandle, useRef } from 'react';

import ButtonIcon from '~/components/atoms/Buttons/Ctas/Button/ButtonIcon/ButtonIcon';
import { cn } from '~/utils';

import ButtonBase from '../ButtonBase';
import styles from './Button.module.css';
import { ButtonProps, ForwardedButtonRef } from './Button.types';
import PlayWithLabelButton from './PlayWithLabelButton/PlayWithLabelButton';

/**
 * CTA component
 * @param buttonVariant The variant of the cta, selected in the CMS, defaults to "pill"
 * @param buttonColorScheme The cta's color scheme, only applies to pill variant, selected in the CMS
 * @param to The cta's link, determines whether component will render as <a> or <button>, set in the CMS
 * @param disabled If the cta is disabled
 * @param className
 */
const Button = (props: ButtonProps, ref: ForwardedRef<ForwardedButtonRef>) => {
  const {
    children,
    buttonVariant = 'pill',
    buttonColorScheme = 'solidLight',
    className,
    to,
    disabled,
    buttonCustomIcon,
  } = props;

  const $icon = useRef(null);
  const $button = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      $icon,
      $button,
    }),
    [],
  );

  const renderClasses = cn(
    (buttonVariant === 'pill' || buttonVariant === 'pillWithCustomIcon') &&
      buttonColorScheme
      ? styles[buttonColorScheme]
      : styles.textLink,
    to?.type === 'phoneNumber' && styles.phoneNumber,
    className,
    disabled && styles.disabled,
  );

  return to?.type === 'videoModal' || buttonVariant === 'playWithLabel' ? (
    <PlayWithLabelButton
      {...props}
      to={to && to?.type === 'videoModal' ? to : undefined}
      buttonRef={$button}
      className={renderClasses}
    >
      {children}
    </PlayWithLabelButton>
  ) : (
    <ButtonBase {...props} ref={$button} className={renderClasses}>
      {buttonVariant === 'pillWithCustomIcon' && (
        <ButtonIcon
          to={to}
          buttonVariant={buttonVariant}
          ref={$icon}
          buttonCustomIcon={buttonCustomIcon}
        />
      )}
      {children}
      {buttonVariant !== 'pillWithCustomIcon' && (
        <ButtonIcon to={to} buttonVariant={buttonVariant} ref={$icon} />
      )}
    </ButtonBase>
  );
};

export default forwardRef(Button);
