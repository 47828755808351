import { RefObject } from 'react';
import { OnProgressProps } from 'react-player/base';
import ReactPlayer from 'react-player/file';

import { CMSImage } from '~/components/atoms/Image/Image.types';

export type VideoDetails = {
  title?: string;
  subtitle?: string;
};

export type CMSVideoProps = {
  url: string;
  name: string;
  isLooping?: boolean;
  isMuted?: boolean;
  aspectRatio?: number;
  mediaType?: 'video';
  thumbnail?: CMSImage;
  willAutoplay?: boolean;
  glowColor?: string;
  videoId?: string;
};

export type VideoProps = {
  src: string;
  className?: string;
  thumbnailClassName?: string;
  isLooping?: boolean;
  isImageSequence?: boolean;
  isMuted?: boolean;
  disablePictureInPicture?: boolean;
  controls?: boolean;
  aspectRatio?: number;
  contain?: boolean;
  forceIsInView?: boolean;
  playsInline?: boolean;
  mediaType?: 'video';
  willAutoplay?: boolean;
  cmsWillAutoplay?: boolean;
  thumbnail?: CMSImage;
  glowColor?: string;
  details?: VideoDetails;
  loopLength?: number;
  shouldFocusControls?: boolean;
  onReady?: (e: HTMLImageElement) => void;
  onVideoReady?: (e: HTMLVideoElement) => void;
  onEnded?: () => void;
};

export type VideoRef = {
  $wrapper: RefObject<HTMLDivElement | null>;
  $player: HTMLVideoElement | null;
  play: () => void;
  pause: () => void;
  restart: (autoplay?: boolean) => void;
  seekTo: (amount: number) => void;
  setVolume: (volume: number) => void;
  muteSmoothly: (duration?: number) => void;
  unmuteSmoothly: (duration?: number) => void;
  forceMute: () => void;
  focusControls: () => void;
  toggleFullscreen: () => void;
};

export type VideoStateSubscriberCallbacks = {
  id: string;
  onProgress?: (progress: Partial<OnProgressProps>) => void;
  onPlay?: () => void;
  onPause?: () => void;
  onMute?: () => void;
  onUnmute?: () => void;
  seekTo?: (amount: number) => void;
  play?: () => void;
  pause?: () => void;
  restart?: (autoplay: boolean) => void;
  mute?: () => void;
  unmute?: () => void;
  forceMute?: () => void;
  forceUnmute?: () => void;
  toggleMute?: () => void;
  toggleFullscreen?: () => void;
};

export type VideoStateProps = {
  $playerRef: RefObject<ReactPlayer>;
  $wrapper: RefObject<HTMLDivElement | null>;
  isMuted: boolean;
  subscribers: VideoStateSubscriberCallbacks[];
  subscribe: (callbacks: VideoStateSubscriberCallbacks) => () => void;
} & Omit<VideoStateSubscriberCallbacks, 'id'>;

// create interface that works with fullscreen api
export interface DocumentWithFullscreen extends Document {
  mozFullScreenElement?: Element;
  webkitFullscreenElement?: Element;
  mozCancelFullScreen?: () => void;
  webkitExitFullscreen?: () => void;
}

export interface ElementWithFullScreen extends Element {
  mozRequestFullscreen?: () => void;
  webkitRequestFullscreen?: () => void;
}

export const SESSION_STORAGE_MUTE = 'frame-video-mute';
