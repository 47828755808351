'use client';
import { mergeRefs } from '@react-aria/utils';
import { gsap } from 'gsap';
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import ButtonClose from '~/components/atoms/Buttons/UI/ButtonClose/ButtonClose';
import Shadow from '~/components/molecules/Shadow/Shadow';
import useUIStore from '~/state/ui';
import { cn, useIsomorphicLayoutEffect as useLayoutEffect } from '~/utils';
import ScrollPosition from '~/utils/domEvents/ScrollPosition/ScrollPosition';
import useFocusTrap from '~/utils/useFocusTrap';

import { fadeInTimeline, fadeOutTimeline } from './Modal.animation';
import { ModalBackgroundType, ModalProps } from './Modal.types';
import styles from './ModalWrapper.module.css';

/**
 * Shared modal functionality. Video modals will always be present in the dom, to enable autoplay in ios, and will only toggle `showModal` to true when triggered (by user interaction or deeplink). All other modals will mount only when triggered, with `showModal` set to true by default
 * @param backgroundType Background type, alpha or blur
 * @param defaultCloseButton Boolean to use the default close button
 * @param shouldClose  Boolean to trigger closing animation
 * @param hasShadow Boolean for whether the modal has a shadow
 * @param footer Footer node, ex. for video playlist
 * @param onClose Function that should change the state that triggers unmounting the modal
 * @param transitionEnter Custom transition enter function passed from component
 * @param transitionLeave Custom transition leave function passed from component
 * @param onEnter Callack for when the modal starts entering (default transition only)
 * @param onLeave Callack for when the modal starts leaving (default transition only)
 * @param showModal Boolean for whether the modal should be shown, set to true by default for modals that only mount when triggered by user interaction. Set to false by default for modals always present in the dom.
 * @param onKeyDown
 * @param wrapperClassName
 * @param backgroundClassName
 * @param contentClassName
 * @example <ModalWrapper showModal={false} />
 */
const ModalWrapper = (
  {
    children,
    className,
    backgroundType = ModalBackgroundType.ALPHA,
    wrapperClassName,
    backgroundClassName,
    closeButtonClassName,
    contentClassName,
    defaultCloseButton = true,
    shouldClose = false,
    hasShadow = true,
    footer,
    onClose,
    onKeyDown,
    transitionEnter,
    transitionLeave,
    onEnter,
    onLeave,
    showModal = true,
  }: ModalProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const isAnimating = useRef<boolean>(false);
  const scrollYStart = useRef(0);
  const $modal = useRef<HTMLDivElement>(null);
  const $modalBackground = useRef<HTMLDivElement>(null);
  const $modalContent = useRef<HTMLDivElement>(null);
  const $modalFooter = useRef<HTMLDivElement>(null);
  const currentFullscreenVideoSrc = useUIStore(
    (state) => state.currentFullscreenVideoSrc,
  );

  const prevWindowOrientation = useRef<number | string | null>();
  const onFocusTrapKeyDown = useFocusTrap($modal);

  useLayoutEffect(() => {
    if (showModal) {
      $modal.current?.focus();
      isAnimating.current = true;

      // default transition
      if (!transitionEnter) {
        fadeInTimeline({
          $modal,
          $modalContent,
          $modalBackground,
          $modalFooter,
          onStart: () => {
            if (onEnter) onEnter();
          },
          onComplete: () => {
            isAnimating.current = false;
          },
        });
      } else {
        // custom transition passed from component
        transitionEnter({
          $modal,
          $modalContent,
          $modalBackground,
          done: () => {
            isAnimating.current = false;
          },
        });
      }
    }
  }, [onEnter, showModal, transitionEnter]);

  const resetContentState = ($el: HTMLDivElement | null) => {
    if (isAnimating.current) {
      return;
    }
    scrollYStart.current = ScrollPosition.y || 0;
    gsap.to($el, {
      y: 0,
      scale: 1,
      opacity: 1,
      duration: 0.2,
    });
  };

  const startClose = useCallback(() => {
    isAnimating.current = true;

    if (transitionLeave) {
      transitionLeave({
        $modal,
        $modalContent,
        $modalBackground,
        done: () => {
          if (onClose) onClose();
          isAnimating.current = false;
        },
      });
      return;
    }

    fadeOutTimeline({
      $modal,
      $modalContent,
      $modalBackground,
      $modalFooter,
      onStart: () => {
        if (onLeave) onLeave();
      },
      onComplete: () => {
        if (onClose) onClose();
      },
    });
  }, [onClose, onLeave, transitionLeave]);

  useEffect(() => {
    if (showModal) {
      scrollYStart.current = ScrollPosition.y || 0;
      const unsubscribe = ScrollPosition.subscribe(({ y: scrollY }) => {
        if (isAnimating.current || !!currentFullscreenVideoSrc) {
          return;
        }
        // window.orientation is deprecated in favor of screen.orientation, but it's not supported in safari
        const orientation = screen.orientation?.type || window.orientation;

        // when rotating on ios, a scroll event gets triggered before the resize/orientation event, causing the modal to close. ignore the scroll event if it's an orientation change, so users don't have to reopen the video to watch in landscape
        if (prevWindowOrientation.current !== orientation) {
          prevWindowOrientation.current = orientation;
          return;
        }
        if (scrollY && scrollYStart.current >= 0) {
          const scrollDiff = scrollY - scrollYStart.current;
          if (Math.abs(scrollDiff) > 100) {
            startClose();
          } else if ($modalContent.current && Math.abs(scrollDiff) > 0) {
            gsap.to([$modalContent.current, $modalFooter.current], {
              y: scrollDiff * -1,
              scale: 1 - Math.abs(scrollDiff) / 1000,
              duration: 0.2,
              overwrite: true,
              onComplete: () => resetContentState($modalContent.current),
            });
          }
        }
      });

      return () => {
        unsubscribe();
      };
    }
  }, [startClose, currentFullscreenVideoSrc, showModal]);

  useEffect(() => {
    if (shouldClose) {
      startClose();
    }
  }, [shouldClose, startClose]);

  useEffect(() => {
    const handleKeyDown = (event: globalThis.KeyboardEvent) => {
      if (isAnimating.current) return;
      if (event.code === 'Escape') {
        startClose();
      }
      if (onKeyDown) onKeyDown(event);

      // Focus trap
      if (onFocusTrapKeyDown) onFocusTrapKeyDown(event);
    };
    if (showModal) {
      window.addEventListener('keydown', handleKeyDown);

      return () => {
        window.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [onFocusTrapKeyDown, onKeyDown, startClose, showModal]);

  const Wrapper = hasShadow ? Shadow : 'div';

  return (
    <section
      ref={mergeRefs(ref, $modal)}
      className={cn(styles.modal, showModal && styles.shown, className)}
      tabIndex={-1}
    >
      <div
        aria-hidden={true}
        ref={$modalBackground}
        className={cn(
          styles.modalBackground,
          styles[backgroundType],
          backgroundClassName,
        )}
        onClick={startClose}
      />
      <Wrapper
        ref={$modalContent}
        className={cn(
          styles.modalWrapper,
          wrapperClassName,
          footer && styles.hasFooter,
        )}
      >
        <div
          aria-modal="true"
          role="dialog"
          className={cn(styles.modalContent, contentClassName)}
        >
          {defaultCloseButton && (
            <ButtonClose
              className={cn(styles.closeButton, closeButtonClassName)}
              onClick={startClose}
            />
          )}
          {children}
        </div>
      </Wrapper>
      {footer && (
        <div ref={$modalFooter} className={styles.modalFooter}>
          {footer}
        </div>
      )}
    </section>
  );
};

export default forwardRef(ModalWrapper);
