export const getItem = (prop: string) => {
  if (typeof sessionStorage === 'undefined' || !sessionStorage) return null;
  const item = sessionStorage.getItem(prop);
  if (item) {
    return JSON.parse(item);
  }
  return null;
};

export const setItem = (prop: string, value: string | boolean) => {
  sessionStorage.setItem(prop, JSON.stringify(value));

  return getItem(prop);
};

export const removeItem = (prop: string) => {
  sessionStorage.removeItem(prop);
};
