'use client';
import { useKeenSlider } from 'keen-slider/react';
import { KeyboardEvent, useCallback, useEffect, useRef } from 'react';
import { FocusableElement, tabbable } from 'tabbable';

import Image from '~/components/atoms/Image/Image';
import { CMSVideoPlaylistVideo } from '~/components/organisms/modules/VideoPlaylist/VideoPlaylist.types';
import { dict } from '~/data/stores/Dictionary';
import decorationsStyles from '~/styles/theme/decorations.module.css';
import { cn, getSpacer, keenSliderConfig } from '~/utils';
import addToRefArray from '~/utils/addToRefArray';

import styles from './ThumbnailsList.module.css';
import { ThumbnailListProps } from './ThumbnailsList.types';
const spacerValue = getSpacer(24);

/**
 * Thumbnails displayed at the bottom of the modal if there is a video list
 * @param videosList Array of objects containing the videos' properties
 * @param currentIndex The selected video's index
 * @param onSelectVideo Callback for when one of the thumbnails is clicked
 * @param className
 * @example <ThumbnailsPlaylist className={...} videosList={...} currentIndex={2}/>
 */
const ThumbnailList = ({
  videosList,
  currentIndex,
  onSelectVideo,
}: ThumbnailListProps) => {
  const $thumbnails = useRef<HTMLButtonElement[]>(Array(videosList.length));
  const $list = useRef<HTMLUListElement>(null);
  const prevActiveElement = useRef<Element | null>(null);

  const [sliderRef, sliderInstanceRef] = useKeenSlider({
    ...keenSliderConfig.defaultConfig,
    selector: '.thumbnailItem',
    mode: 'snap',
    drag: true,
    loop: false,
    initial: currentIndex,
    slides: {
      perView: 'auto',
      number: videosList.length,
      spacing: spacerValue,
    },
  });

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLElement>) => {
      if (e.key === 'Tab') {
        e.stopPropagation();
        e.preventDefault();
        // determine the sequence of tabbable elements contained
        // within our parent
        const sequence = tabbable($list.current as Element);

        if (sequence.length === 0) {
          return;
        }

        if (
          document.activeElement === $thumbnails.current[0] &&
          currentIndex > 0
        ) {
          let isPrevActiveElementAThumbnail = false;
          sequence.some((element: FocusableElement) => {
            if (element === prevActiveElement.current) {
              isPrevActiveElementAThumbnail = true;
            }
          });

          if (!isPrevActiveElementAThumbnail) {
            sequence[currentIndex].focus();
            return;
          }
        }

        prevActiveElement.current = document.activeElement;

        let currIndex = 0;
        sequence.some((element: FocusableElement, index: number) => {
          if (element === document.activeElement) {
            currIndex = index;
            return true;
          }
          return false;
        });

        const backwards = e.shiftKey;
        const nextIndex = backwards ? currIndex - 1 : currIndex + 1;
        const nextToFocus = sequence[nextIndex];

        if (nextToFocus) {
          nextToFocus.focus();
          sliderInstanceRef.current?.moveToIdx(nextIndex);
          return;
        }
      }
    },
    [currentIndex],
  );

  useEffect(() => {
    sliderInstanceRef.current?.moveToIdx(currentIndex);
  }, [currentIndex]);

  useEffect(() => {
    prevActiveElement.current = document.activeElement as Element;
  }, []);

  return (
    <div className={styles.wrapper} ref={sliderRef}>
      <ul
        className={styles.thumbnailsList}
        ref={$list}
        // hack to keep keyboard event on this element
        role="presentation"
        onKeyDown={handleKeyDown}
      >
        {videosList &&
          videosList.map(
            (videosListItem: CMSVideoPlaylistVideo, index: number) => {
              return (
                <li
                  className={cn(
                    styles.thumbnailListItem,
                    'thumbnailItem',
                    index === currentIndex && styles.active,
                  )}
                  key={videosListItem._key}
                >
                  <button
                    ref={(ref) =>
                      ref &&
                      addToRefArray({
                        element: ref,
                        refArray: $thumbnails,
                        index,
                      })
                    }
                    className={styles.itemThumbnailButton}
                    onClick={() => onSelectVideo(videosListItem)}
                    aria-label={dict('clickToWatchVideo')}
                  >
                    <Image
                      className={styles.itemThumbnailImage}
                      source={videosListItem.poster.image}
                    />
                    <div className={styles.itemDetails}>
                      <h3
                        className={cn(
                          styles.itemDetailsTitle,
                          decorationsStyles.clampAndTruncate,
                        )}
                      >
                        {videosListItem.title}
                      </h3>
                      <div className={styles.itemDetailsDescription}>
                        <h4 className={styles.itemDetailsSubtitle}>
                          {videosListItem.subtitle}
                        </h4>
                      </div>
                    </div>
                  </button>
                </li>
              );
            },
          )}
      </ul>
    </div>
  );
};

export default ThumbnailList;
