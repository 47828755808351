import { create } from 'zustand';

const variant = create<{
  variantName: string;
  setVariantName: (variantName: string) => void;
}>((set) => ({
  variantName: 'default',
  setVariantName: (variantName: string) => {
    set({ variantName });
  },
}));

export default variant;
