'use client';
import gsap from 'gsap';
import debounce from 'lodash/debounce';
import noop from 'lodash/noop';
import {
  MouseEvent,
  useCallback,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react';
import { OnProgressProps } from 'react-player/base';

import { cn } from '~/utils';

import { formatMMSS } from '../../utils/formatMMSS';
import styles from './Track.module.css';
import { TimeFormat, TrackProps } from './Track.types';

const Track = ({ className, duration, videoState }: TrackProps) => {
  const [isDragging, setIsDragging] = useState(false);
  const [timeFormat, setTimeFormat] = useState<TimeFormat>('remaining');
  const [barWidth, setBarWidth] = useState<number>(0);
  const $barContainer = useRef<HTMLDivElement>(null);
  const $trackContainer = useRef<HTMLDivElement>(null);
  const $remaningTimeContainer = useRef<HTMLButtonElement>(null);
  const playedSecondsRef = useRef(0);

  const handleMouseDown = useCallback(() => {
    setIsDragging(true);
  }, []);

  const handleMouseMove = (event: MouseEvent<HTMLDivElement>) => {
    if (isDragging)
      videoState.current?.seekTo?.(
        (event.nativeEvent.offsetX / barWidth) * duration,
      );
  };

  const handleMouseUp = (event: MouseEvent<HTMLDivElement>) => {
    if (isDragging)
      videoState.current?.seekTo?.(
        (event.nativeEvent.offsetX / barWidth) * duration,
      );
    setIsDragging(false);
  };

  const updateRemainingTime = useCallback(() => {
    const time =
      timeFormat === 'remaining'
        ? `-${formatMMSS(duration - playedSecondsRef.current)}`
        : formatMMSS(playedSecondsRef.current);

    if ($remaningTimeContainer.current)
      $remaningTimeContainer.current.innerHTML = time;
  }, [timeFormat, duration]);

  useEffect(() => {
    updateRemainingTime();
  }, [updateRemainingTime]);

  const toggleRemainingTime = useCallback(() => {
    if (timeFormat === 'remaining') setTimeFormat('elapsed');
    else setTimeFormat('remaining');
  }, [timeFormat]);

  const onProgress = useCallback(
    ({ loadedSeconds, playedSeconds }: Partial<OnProgressProps>) => {
      if (
        typeof loadedSeconds !== 'undefined' &&
        typeof playedSeconds !== 'undefined'
      ) {
        playedSecondsRef.current = playedSeconds;

        gsap.set($trackContainer.current, {
          '--buffer-progress': `${(1 - loadedSeconds / duration) * -100}%`,
          '--playing-progress': `${(1 - playedSeconds / duration) * -100}%`,
        });

        updateRemainingTime();
      }
    },
    [updateRemainingTime],
  );

  const id = useId();
  useEffect(() => {
    let unsubscribe = noop;

    if (videoState.current) {
      unsubscribe = videoState.current.subscribe({
        id,
        onProgress,
      });
    }

    return () => {
      unsubscribe({ id });
    };
  }, [timeFormat, duration]);

  useEffect(() => {
    const observer = new ResizeObserver(
      debounce((entries) => {
        setBarWidth(entries[0].contentRect.width);
      }, 100),
    );

    if ($barContainer.current) observer.observe($barContainer.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className={cn(styles.track, className)} ref={$trackContainer}>
      <div
        className={styles.trackBars}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        // hidden for screen rearders
        aria-hidden={true}
        ref={$barContainer}
      >
        <div className={styles.durationBar}>
          <div className={styles.trackBufferBar}></div>
          <div className={styles.trackProgressBar}></div>
          <div className={styles.trackPointer}></div>
        </div>
      </div>

      <button
        className={styles.remainingTime}
        onClick={toggleRemainingTime}
        ref={$remaningTimeContainer}
      ></button>
    </div>
  );
};

export default Track;
