import SvgPauseIcon from '~/assets/svg/pause.svg';
import SvgPlayIcon from '~/assets/svg/play.svg';
import { cn } from '~/utils';

import styles from './PlayPauseButton.module.css';
import { PlayPauseProps } from './PlayPauseButton.types';

const PlayPauseButton = ({
  className,
  isPlaying,
  isRound = false,
  onClick,
}: PlayPauseProps) => {
  return (
    <button
      onClick={onClick}
      className={cn(
        styles.playPauseButton,
        isRound && styles.isRound,
        className,
      )}
    >
      <span className="visuallyHidden">
        {/* TODO: to replace with dictionary entry */}
        {isPlaying ? 'Pause video' : 'play video'}
      </span>
      <span className={styles.buttonInner}>
        <SvgPlayIcon
          className={cn(styles.playIcon, !isPlaying && styles.isActive)}
        />
        <SvgPauseIcon
          className={cn(styles.pauseIcon, isPlaying && styles.isActive)}
        />
      </span>
    </button>
  );
};

export default PlayPauseButton;
