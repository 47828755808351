import { RefObject } from 'react';
import { OnProgressProps } from 'react-player/base';
import ReactPlayer from 'react-player/file';

import { setItem } from '~/utils/sessionStorage';

import {
  DocumentWithFullscreen,
  ElementWithFullScreen,
  SESSION_STORAGE_MUTE,
  VideoStateSubscriberCallbacks,
} from '../Video.types';

// class to manage the video's progress and pass played seconds and loaded seconds in a callback to subscriber components

class VideoState {
  subscribers: VideoStateSubscriberCallbacks[];
  isPlaying: boolean;
  playedSeconds: number;
  loadedSeconds: number;
  isMuted: boolean;
  $wrapper: RefObject<HTMLDivElement>;
  $playerRef: RefObject<ReactPlayer>;

  constructor(
    $playerRef: RefObject<ReactPlayer>,
    $wrapper: RefObject<HTMLDivElement>,
    isInitiallyMuted: boolean,
  ) {
    this.subscribers = [];

    this.$playerRef = $playerRef;
    this.$wrapper = $wrapper;

    // initial states
    this.isPlaying = false;
    this.playedSeconds = 0;
    this.loadedSeconds = 0;
    this.isMuted = isInitiallyMuted;
  }

  onProgress(progress: Partial<OnProgressProps>) {
    const { playedSeconds, loadedSeconds } = progress;
    if (playedSeconds && loadedSeconds) {
      this.playedSeconds = playedSeconds;
      this.loadedSeconds = loadedSeconds;

      for (let i = 0; i < this.subscribers.length; i++) {
        this.subscribers[i].onProgress?.({ playedSeconds, loadedSeconds });
      }
    }
  }

  onPlay() {
    for (let i = 0; i < this.subscribers.length; i++) {
      this.subscribers[i].onPlay?.();
    }
  }

  onPause() {
    for (let i = 0; i < this.subscribers.length; i++) {
      this.subscribers[i].onPause?.();
    }
  }

  seekTo(amount: number) {
    this.$playerRef.current?.seekTo(amount);
  }

  play() {
    try {
      this.$playerRef.current?.getInternalPlayer().play();
      this.isPlaying = true;
    } catch (e) {}
  }

  pause() {
    try {
      this.$playerRef.current?.getInternalPlayer().pause();
      this.isPlaying = false;
    } catch (e) {}
  }

  restart(autoplay: boolean) {
    this.seekTo(0);
    this.pause();
    if (autoplay) {
      if (!this.isPlaying) {
        this.play();
      }
    }
  }

  // mute and unmute are set when the toggle mute button is clicked
  mute() {
    this.isMuted = true;

    setItem(SESSION_STORAGE_MUTE, true);

    for (let i = 0; i < this.subscribers.length; i++) {
      this.subscribers[i].onMute?.();
    }
  }

  unmute() {
    this.isMuted = false;

    setItem(SESSION_STORAGE_MUTE, false);

    for (let i = 0; i < this.subscribers.length; i++) {
      this.subscribers[i].onUnmute?.();
    }
  }

  // forceMute and forceUnmute are not set by user action so don't update the session storage here
  forceMute() {
    this.isMuted = true;
    for (let i = 0; i < this.subscribers.length; i++) {
      this.subscribers[i].onMute?.();
    }
  }

  forceUnmute() {
    this.isMuted = false;
    for (let i = 0; i < this.subscribers.length; i++) {
      this.subscribers[i].onUnmute?.();
    }
  }

  toggleMute() {
    if (this.isMuted) {
      this.unmute();
    } else {
      this.mute();
    }
  }

  toggleFullscreen() {
    const element = this.$wrapper.current as ElementWithFullScreen;
    const doc = document as DocumentWithFullscreen;

    if (
      doc.fullscreenElement ||
      doc.webkitFullscreenElement ||
      doc.mozFullScreenElement
    ) {
      if (doc.webkitExitFullscreen) {
        doc.webkitExitFullscreen();
      } else if (doc.mozCancelFullScreen) {
        doc.mozCancelFullScreen();
      } else {
        doc.exitFullscreen();
      }
    } else if (element) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.mozRequestFullscreen) {
        element.mozRequestFullscreen();
      } else {
        try {
          (
            this.$playerRef.current?.getInternalPlayer() as HTMLVideoElement & {
              webkitEnterFullscreen: () => void;
            }
          ).webkitEnterFullscreen();
        } catch (e) {
          console.warn('Fullscreen is not supported on your browser');
        }
      }
    }
  }

  subscribe(callbacks: VideoStateSubscriberCallbacks) {
    this.subscribers.push(callbacks);

    return () => {
      this.subscribers = this.subscribers.filter(
        (cb) => cb.id !== callbacks.id,
      );
    };
  }
}

export default VideoState;
