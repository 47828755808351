'use client';
/**
 * Renders the icon for the default CTAs. Renders a download icon for download links, and arrow icon for internal and external links.
 * @param buttonVariant The variant of the parent cta, determines whether icon should render. Icon will only be paired with "textWithIcon" variant.
 * @param to The parent CTA's link
 * @example <ButtonIcon buttonVariant={buttonVariant} to-{to} />
 */
import { ForwardedRef, forwardRef } from 'react';

import SvgDownload from '~/assets/svg/download.svg';
import SvgPhone from '~/assets/svg/phone.svg';
import SvgPlayIcon from '~/assets/svg/play-icon.svg';
import Graphic from '~/components/atoms/Graphic/Graphic';
import { cn } from '~/utils';

import AnimatedSvgArrow from '../../../AnimatedSvgArrow/AnimatedSvgArrow';
import styles from './ButtonIcon.module.css';
import { ButtonIconProps } from './ButtonIcon.types';

const ButtonIcon = (
  { className, buttonVariant, to, buttonCustomIcon }: ButtonIconProps,
  ref: ForwardedRef<HTMLElement>,
) => {
  if (buttonVariant === 'textWithIcon') {
    return (
      <span className={cn(styles.arrowContainer, className)} ref={ref}>
        <AnimatedSvgArrow />
      </span>
    );
  } else if (buttonVariant === 'pillWithCustomIcon' && buttonCustomIcon) {
    return (
      <span
        className={cn(
          styles.iconContainer,
          styles.customIconContainer,
          className,
        )}
        ref={ref}
      >
        <Graphic
          {...buttonCustomIcon}
          className={styles.customIcon}
          disableAutoSize={true}
        />
      </span>
    );
  } else {
    switch (to?.type) {
      case 'phoneNumber':
        return (
          <span
            className={cn(
              styles.iconContainer,
              styles.phoneIconContainer,
              className,
            )}
          >
            <SvgPhone className={styles.phoneIcon} ref={ref} />
          </span>
        );

      case 'download':
        return (
          <span
            className={cn(
              styles.iconContainer,
              styles.downloadIconContainer,
              className,
            )}
            ref={ref}
          >
            <SvgDownload className={styles.icon} />
          </span>
        );

      case 'videoModal':
        return (
          <span
            className={cn(
              styles.iconContainer,
              styles.playIconContainer,
              className,
            )}
            ref={ref}
          >
            <SvgPlayIcon className={styles.playIcon} />
          </span>
        );
    }
  }
};

export default forwardRef(ButtonIcon);
