import variantStore from './Variant.store';

const getBaseTrackingData = () => {
  return {
    client: 'marketing site',
    page: window.location.pathname,
    query_param: window.location.search,
    variant: variantStore.getState().variantName,
  };
};

export default getBaseTrackingData;
