'use client';
/**
 * Rounded button with play icon
 * @param props see UiButtonBase props
 * @example  <ButtonPlay />
 */
import { ForwardedRef, forwardRef } from 'react';

import SvgPlayIcon from '~/assets/svg/visuallyCenteredPlay.svg';
import { ForwardedButtonRef } from '~/components/atoms/Buttons/Ctas/Button/Button.types';
import UIButtonBase from '~/components/atoms/Buttons/UI/UIButtonBase';
import { dict } from '~/data/stores/Dictionary';
import { cn } from '~/utils';

import { UIButtonProps } from '../UIButtonBase.types';
import styles from './ButtonPlay.module.css';

const ButtonPlay = (
  props: UIButtonProps,
  ref: ForwardedRef<ForwardedButtonRef>,
) => {
  const { className } = props;

  return (
    <UIButtonBase
      ref={ref}
      {...props}
      label={dict('play')}
      className={cn(styles.buttonPlay, className)}
    >
      <SvgPlayIcon />
    </UIButtonBase>
  );
};

export default forwardRef(ButtonPlay);
