'use client';
import { mergeRefs } from '@react-aria/utils';
import { ForwardedRef, forwardRef, useEffect, useRef } from 'react';

import SharedIntersectionObserver from '~/utils/observers/SharedIntersectionObserver';

import { ObserverProps } from './Observer.types';

/**
 * A generic intersection observer observer to wrap multiple elements.
 * @param options The intersection observer options
 * @param once If the observer should fire only once - defaults to true
 * @param callback The callback fired on intersection, has the DOMRect as a param
 * @param className
 * @example <Observer options={{rootMargin: "200% 0%"}} callback={myCallback}>
 * <MyCustomComponent />
 * </Observer>
 */
const Observer = (
  {
    children,
    options = {},
    once = true,
    callback,
    onExit,
    className,
    style,
  }: ObserverProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const $wrapper = useRef<HTMLDivElement>(null);

  const stringifiedOptions = JSON.stringify(options);

  useEffect(() => {
    if ($wrapper.current) {
      const $element = $wrapper.current;
      // Let's setup the intersection observer
      SharedIntersectionObserver.subscribe(
        $element,
        (rect) => {
          if (rect) {
            callback(rect);
            if ($element && once) {
              SharedIntersectionObserver.unsubscribe($element, options);
            }
          } else if (onExit) {
            onExit();
          }
        },
        options,
      );

      return () => {
        if ($element) {
          SharedIntersectionObserver.unsubscribe($element, options);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, once, stringifiedOptions]);

  return (
    <div ref={mergeRefs(ref, $wrapper)} className={className} style={style}>
      {children}
    </div>
  );
};

export default forwardRef(Observer);
