'use client';
import noop from 'lodash/noop';
import {
  ForwardedRef,
  forwardRef,
  KeyboardEvent,
  useCallback,
  useEffect,
  useId,
  useImperativeHandle,
  useRef,
} from 'react';
import { OnProgressProps } from 'react-player/base';
import useStateRef from 'react-usestateref';
import { FocusableElement, tabbable } from 'tabbable';

import SvgExpandIcon from '~/assets/svg/expand.svg';
import { dict } from '~/data/stores/Dictionary';
import { cn } from '~/utils';
import { tickerAdd, tickerRemove } from '~/utils/ticker';

import MuteUnmuteButton from './Buttons/MuteUnmuteButton/MuteUnmuteButton';
import PlayPauseButton from './Buttons/PlayPauseButton/PlayPauseButton';
import styles from './Controls.module.css';
import { ControlPanelProps, ControlPanelRef } from './Controls.types';
import Track from './Track/Track';

// seconds
const TIMEOUT_AMOUNT = 3;
const KEYBOARD_CONTROL_TIME_AMOUNT = 5;

const Controls = (
  {
    className,
    details,
    isMuted,
    isPlaying,
    showMute = true,
    showTrack = true,
    shouldFocus = false,
    videoState,
    duration,
  }: ControlPanelProps,
  ref: ForwardedRef<ControlPanelRef>,
) => {
  const [isVisible, setIsVisible, isVisibleRef] = useStateRef(false);
  const $container = useRef<HTMLDivElement>(null);
  const lastMoveTimeRef = useRef<number>(Date.now());
  const playedSecondsRef = useRef(0);

  const togglePlayPause = () => {
    if (isPlaying) {
      videoState.current?.pause?.();
    } else {
      videoState.current?.play?.();
    }
  };

  const toggleMute = () => {
    videoState.current?.toggleMute?.();
  };

  const handleMouseMove = useCallback(() => {
    if (!isVisibleRef.current) {
      setIsVisible(true);
    }

    lastMoveTimeRef.current = Date.now();

    // We don't want to add a ref to a dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsVisible]);

  const handleMouseLeave = useCallback(() => {
    if (isVisibleRef.current) {
      setIsVisible(false);
    }
    // We don't want to add a ref to a dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsVisible]);

  const toggleFullscreen = () => {
    videoState.current?.toggleFullscreen?.();
  };

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLDivElement>) => {
      // Play/Pause
      if (event.code === 'Space' || event.key === 'k') {
        togglePlayPause();
        event.preventDefault();
      }
      // Toggle Mute
      if (event.key === 'm') {
        toggleMute();
      }
      // Toggle Fullscreen
      if (event.key === 'f') {
        toggleFullscreen();
      }
      // Fast Backward
      if (event.code === 'ArrowLeft') {
        videoState.current?.seekTo?.(
          playedSecondsRef.current - KEYBOARD_CONTROL_TIME_AMOUNT,
        );
      }
      // Faster Backward
      if (event.key === 'j') {
        videoState.current?.seekTo?.(
          playedSecondsRef.current - KEYBOARD_CONTROL_TIME_AMOUNT * 2,
        );
      }
      // Fast Forward
      if (event.code === 'ArrowRight') {
        videoState.current?.seekTo?.(
          playedSecondsRef.current + KEYBOARD_CONTROL_TIME_AMOUNT,
        );
      }
      // Faster Forward
      if (event.key === 'l') {
        videoState.current?.seekTo?.(
          playedSecondsRef.current + KEYBOARD_CONTROL_TIME_AMOUNT * 2,
        );
      }

      // Focus
      if (event.code === 'Tab') {
        // determine the sequence of tabbable elements contained
        // within our parent
        const sequence = tabbable($container.current as Element);

        const currentlyFocused = sequence.some((element: FocusableElement) => {
          if (element === document.activeElement) {
            return true;
          }
          return false;
        });

        if (currentlyFocused) {
          setIsVisible(true);
          return;
        }
      }
    },
    [setIsVisible],
  );

  const onProgress = ({ playedSeconds }: Partial<OnProgressProps>) => {
    playedSecondsRef.current = playedSeconds || 0;
  };

  const id = useId();

  useEffect(() => {
    let unsubscribe = noop;

    if (videoState.current) {
      unsubscribe = videoState.current.subscribe({
        id,
        onProgress,
      });
    }

    return () => {
      unsubscribe({ id });
    };
  }, []);

  useEffect(() => {
    // Focus so that Keyboard events get triggered
    if (shouldFocus) $container.current?.focus();
  }, [shouldFocus]);

  useEffect(() => {
    const tick = () => {
      if (Date.now() - lastMoveTimeRef.current > TIMEOUT_AMOUNT * 1000) {
        setIsVisible(false);
      }
    };

    if (isVisible) {
      tickerAdd(tick);
    }

    return () => {
      if (isVisible) {
        tickerRemove(tick);
      }
    };
  }, [isVisible, setIsVisible]);

  useImperativeHandle(
    ref,
    () => ({
      focus() {
        $container.current?.focus();
      },
    }),
    [],
  );

  return (
    <div
      ref={$container}
      className={cn(styles.controlPanel, className)}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onKeyDown={handleKeyDown}
      role="toolbar"
      tabIndex={0}
    >
      <PlayPauseButton
        className={cn(
          styles.centerPlayPauseButton,
          (isVisible || !isPlaying) && styles.isVisible,
        )}
        isPlaying={isPlaying}
        isRound={true}
        onClick={togglePlayPause}
      ></PlayPauseButton>

      <div className={cn(styles.bottomPanel, isVisible && styles.isVisible)}>
        {details && details.title && (
          <div className={styles.bottomPanelDetails}>
            <p className={styles.detailsTitle}>{details.title}</p>
            <p className={styles.detaislSubtitle}>{details.subtitle}</p>
          </div>
        )}
        <div className={styles.bottomPanelControls}>
          <PlayPauseButton
            className={styles.bottomPanelPlayPauseButton}
            isPlaying={isPlaying}
            onClick={togglePlayPause}
          />
          {showMute && (
            <MuteUnmuteButton
              className={styles.bottomPanelMuteUnmuteButton}
              isMuted={isMuted}
              onClick={toggleMute}
            />
          )}
          {showTrack && (
            <Track
              className={cn(styles.track, showMute && styles.hasMuteButton)}
              videoState={videoState}
              duration={duration}
            />
          )}
          <button
            className={styles.bottomPanelExpandButton}
            onClick={toggleFullscreen}
          >
            <span className="visuallyHidden">
              {dict('clickToToggleFullscreen')}
            </span>
            <SvgExpandIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default forwardRef<ControlPanelRef, ControlPanelProps>(Controls);
