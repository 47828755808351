import getBaseTrackingData from './getBaseTrackingData';

const trackEventAdobeAnalytics = (eventName: string, properties?: object) => {
  if (window._satellite) {
    const baseTrackingData = getBaseTrackingData();

    window._satellite.track('event', {
      xdm: {},
      data: {
        web: {
          webInteraction: {
            name: eventName,
            URL: baseTrackingData.page,
          },
        },
        _adobe_corpnew: {
          digitalData: {
            baseTrackingData,
            properties,
          },
        },
      },
    });
  }
};

export default trackEventAdobeAnalytics;
