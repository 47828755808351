'use client';
import { ForwardedRef, forwardRef } from 'react';

import ClientOnlyPortal from '~/utils/ClientOnlyPortal/ClientOnlyPortal';

import { ModalBackgroundType, ModalProps } from './Modal.types';
import ModalWrapper from './ModalWrapper';

const Modal = (
  {
    backgroundClassName,
    backgroundType = ModalBackgroundType.ALPHA,
    children,
    className,
    contentClassName,
    closeButtonClassName,
    defaultCloseButton = true,
    footer,
    hasShadow = true,
    onClose,
    onEnter,
    onKeyDown,
    onLeave,
    shouldClose,
    transitionEnter,
    transitionLeave,
    wrapperClassName,
    showModal,
  }: ModalProps,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return (
    <ClientOnlyPortal>
      <ModalWrapper
        ref={ref}
        className={className}
        backgroundType={backgroundType}
        backgroundClassName={backgroundClassName}
        contentClassName={contentClassName}
        wrapperClassName={wrapperClassName}
        closeButtonClassName={closeButtonClassName}
        defaultCloseButton={defaultCloseButton}
        shouldClose={shouldClose}
        onClose={onClose}
        onKeyDown={onKeyDown}
        hasShadow={hasShadow}
        transitionEnter={transitionEnter}
        transitionLeave={transitionLeave}
        onEnter={onEnter}
        onLeave={onLeave}
        footer={footer}
        showModal={showModal}
      >
        {/* Modal content here */}
        {children}
      </ModalWrapper>
    </ClientOnlyPortal>
  );
};

export default forwardRef(Modal);
