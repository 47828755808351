'use client';
// taken from https://github.com/vercel/next.js/tree/canary/examples/with-portals
// To avoid SSR issues
import noop from 'lodash/noop';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { ClientOnlyPortalProps } from './ClientOnlyPortal.types';

export default function ClientOnlyPortal({
  children,
  selector = '#modal',
  onMount = noop,
}: ClientOnlyPortalProps) {
  const container = useRef<Element>();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    container.current = document.querySelector(selector) as Element;
    setMounted(true);
    onMount();
  }, [selector, onMount]);

  return mounted ? createPortal(children, container.current as Element) : null;
}
