import SvgMuteSpeakerIcon from '~/assets/svg/mute-speaker.svg';
import SvgSpeakerIcon from '~/assets/svg/speaker.svg';
import { cn } from '~/utils';

import styles from './MuteUnmuteButton.module.css';
import { MuteUnmuteProps } from './MuteUnmuteButton.types';

const MuteUnmuteButton = ({ className, isMuted, onClick }: MuteUnmuteProps) => {
  return (
    <button
      onClick={onClick}
      className={cn(styles.muteUnmuteButton, className)}
    >
      {/* TODO: to replace with dictionary entry */}
      <span className="visuallyHidden">{isMuted ? 'unmute' : 'mute'}</span>
      <span className={styles.buttonInner}>
        <SvgSpeakerIcon
          className={cn(styles.icon, !isMuted && styles.isActive)}
        />
        <SvgMuteSpeakerIcon
          className={cn(styles.icon, isMuted && styles.isActive)}
        />
      </span>
    </button>
  );
};

export default MuteUnmuteButton;
