import { RefArray } from '~/components/molecules/PortableText/PortableText.types';

/**
 * addToRefArray utility:
 * @param elementToAdd The current element's ref to add to the array.
 * The original intention was to only support Element, but this is also being used
 * for refs of all sorts (example: GlowRef).
 * @param refArray The pre-existing array that you are adding to.
 * @param index (optional) The specific index you'd like to place your element.
 * If empty, your ref will be added to the end of the array.
 * @param emptyOnInit Empty the array before adding your ref.
 */

type AddToRefArrayProps = {
  element: any;
  refArray: RefArray | RefArray[];
  index?: number;
  emptyOnInit?: boolean;
};

const addToRefArray = ({
  element,
  refArray,
  index = -1,
  emptyOnInit = false,
}: AddToRefArrayProps) => {
  if (
    typeof element !== 'undefined' &&
    element &&
    typeof refArray !== 'undefined' &&
    refArray
  ) {
    function pushElementToArray(refArray: RefArray) {
      if (Array.isArray(refArray.current)) {
        if (emptyOnInit) {
          refArray.current.splice(0);
        }

        if (index === -1) {
          refArray.current.push(element);
        } else {
          refArray.current[index] = element;
        }
      } else {
        console.warn(
          'Cannot add to Ref Array. Ensure ref is properly defined. Example: useRef([] as HTMLDivElement[]);',
        );
      }
    }

    if (refArray) {
      if (Array.isArray(refArray)) {
        for (const arrayItem of refArray) {
          pushElementToArray(arrayItem);
        }
      } else {
        pushElementToArray(refArray);
      }
    }
  }
};

export default addToRefArray;
