'use client';
/**
 * Rounded button with close icon
 * @param props see UiButtonBase props
 * @example  <ButtonClose className={styles.closeButton} onClick={startClose} />
 */
import { ForwardedRef, forwardRef } from 'react';

import SvgCloseIcon from '~/assets/svg/close-icon.svg';
import { ForwardedButtonRef } from '~/components/atoms/Buttons/Ctas/Button/Button.types';
import { dict } from '~/data/stores/Dictionary';

import UIButtonBase from '../UIButtonBase';
import { UIButtonProps } from '../UIButtonBase.types';

const ButtonClose = (
  props: UIButtonProps,
  ref: ForwardedRef<ForwardedButtonRef>,
) => {
  return (
    <UIButtonBase ref={ref} {...props} label={dict('clickToClose')}>
      <SvgCloseIcon />
    </UIButtonBase>
  );
};

export default forwardRef(ButtonClose);
