/**
 * Arrow that pairs with TextWithLink button variant, with animated hover state
 * @param direction Direction of the arrow, defaults to 'next'
 * @param className
 * @example <AnimatedSvgArrow direction={'previous} />
 */
import { cn } from '~/utils';

import styles from './AnimatedSvgArrow.module.css';
import { ArrowProps } from './AnimatedSvgArrow.types';

const AnimatedSvgArrow = ({ className, direction = 'next' }: ArrowProps) => {
  return (
    <span
      className={cn(
        styles.arrowWrapper,
        className,
        direction === 'previous' && styles.previous,
      )}
    >
      <span className={styles.cssTail}></span>
      <svg
        className={styles.arrowSvg}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32.2 54.4"
      >
        <path
          className={styles.arrowHead}
          d="M30.8,23.6c2,2,2,5.1,0,7.1L8.6,52.9c-1.9,2-5.1,2-7.1,0c-2-1.9-2-5.1,0-7.1c0,0,0,0,0,0l18.7-18.7L1.5,8.5
	c-1.9-2-1.8-5.2,0.1-7.1c1.9-1.9,5-1.9,6.9,0C8.6,1.4,30.8,23.6,30.8,23.6z"
        />
      </svg>
    </span>
  );
};

export default AnimatedSvgArrow;
